//@author: devin
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    NgZone,
    Renderer2,
    ViewEncapsulation,
} from "@angular/core";
import { S25ItemComponent } from "../s25.item.component";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-item-organization")
@Component({
    selector: "s25-item-organization",
    template: `
        <s25-item
            *ngIf="init"
            [modelBean]="modelBean"
            [includeTypeIcon]="includeTypeIcon"
            [popoverTemplate]="organizationTooltip"
        ></s25-item>

        <ng-template #organizationTooltip>
            <div class="qtip-content org-info">
                <div *ngIf="!modelBean.initPopOver">Loading...</div>
                <div *ngIf="modelBean.initPopOver && !modelBean.data?.item" class="organizationBox popoverroot">
                    Could not load organization details
                </div>
                <div *ngIf="modelBean.initPopOver && modelBean.data?.item" class="organizationBox popoverroot">
                    <div
                        class="boxHeader"
                        (contextmenu)="contextMenu({ itemTypeId: 2, itemId: modelBean.itemId }, $event)"
                    >
                        <div class="boxHeaderName">{{ modelBean.data.item.item_name }}</div>
                    </div>
                    <div class="boxBody">
                        <div *ngFor="let row of modelBean.data.item.rows; trackBy: trackByFn">
                            <span class="c-dataLabel"> {{ row.name }}</span>
                            <span> {{ row.value }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    `,

    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ItemOrganizationComponent extends S25ItemComponent {
    constructor(
        elementRef: ElementRef,
        zone: NgZone,
        cd: ChangeDetectorRef,
        componentFactoryResolver: ComponentFactoryResolver,
        renderer: Renderer2,
    ) {
        super(elementRef, zone, cd, componentFactoryResolver, renderer);
        this.itemTypeId = 2;
    }
}
