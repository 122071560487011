import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewRef,
} from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { StateI } from "../../pojo/StateI";
import { InlineLoadingI } from "../../pojo/InlineLoadingI";
import { StyleI } from "../../pojo/StyleI";

const S25_LOADING_LANG_DEFAULT = "Loading...";

interface Bean extends InlineLoadingI {
    style: StyleI;
    text?: string;
}

@TypeManagerDecorator("s25-loading-inline")
@Component({
    selector: "s25-loading-inline",
    template: ` <div class="s25-ng" *ngIf="state.isActive()">
        <div class="s25-loading s25loading-inline" [ngClass]="bean.style.class" [attr.id]="bean.style.id">
            <div class="s25-icon-container c-loadingContainer">
                <svg class="c-loading" x="0px" y="0px" viewBox="0 0 150 150">
                    <circle class="c-loading__inner" cx="75" cy="75" r="60" />
                </svg>
                <div class="s25-text">{{ bean.text }}</div>
            </div>
        </div>
    </div>`,
    styles: `
        .s25-loading {
            overflow: hidden;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})

// model: {text: String, style: {id: String, class: String}}
export class S25LoadingInlineComponent implements OnInit {
    @Input() model: InlineLoadingI = undefined;
    state: StateI;
    bean: Bean = { style: {} };

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this;
        this.state = S25Util.directive.createStateInstance();
    }

    ngOnInit() {
        S25Util.extend(this.bean, this.model, { text: (this.model && this.model.text) || S25_LOADING_LANG_DEFAULT });
        this.model && this.model.isInit && this.init();
    }

    init() {
        this.state.init();
        this.cd && !(this.cd as ViewRef).destroyed && this.cd.detectChanges();
    }

    destroy(removeFromDOM?: boolean) {
        this.state.destroy();
        this.cd && !(this.cd as ViewRef).destroyed && this.cd.detectChanges();

        removeFromDOM && this.elementRef.nativeElement.remove();
    }
}
