import { Component, Input } from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-loading-inline-static")
@Component({
    selector: "s25-ng-loading-inline-static",
    template: `
        <svg class="c-loading" x="0px" y="0px" viewBox="0 0 150 150">
            <circle class="c-loading__inner" cx="75" cy="75" r="60" />
        </svg>
        <span *ngIf="!!text" class="s25-text">{{ text }}</span>
    `,
    styles: `
        span {
            margin-left: 0.5em;
            margin-block: auto;
        }
    `,
})
export class S25LoadingInlineStaticComponent {
    @Input() text = "Loading...";
}
