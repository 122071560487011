//@author: devin
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    NgZone,
    Renderer2,
    ViewEncapsulation,
} from "@angular/core";
import { S25ItemComponent } from "../s25.item.component";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-item-contact")
@Component({
    selector: "s25-item-contact",
    template: `
        <s25-item
            *ngIf="init"
            [modelBean]="modelBean"
            [includeTypeIcon]="includeTypeIcon"
            [popoverTemplate]="contactTooltip"
        ></s25-item>

        <ng-template #contactTooltip>
            <div class="qtip-content">
                <div *ngIf="!modelBean.initPopOver">Loading...</div>
                <div *ngIf="modelBean.initPopOver && !modelBean.data?.item" class="contactBox popoverroot">
                    Could not load contact details
                </div>
                <div *ngIf="modelBean.initPopOver && modelBean.data?.item" class="contactBox popoverroot">
                    <div
                        class="boxHeader"
                        (contextmenu)="contextMenu({ itemTypeId: 3, itemId: modelBean.itemId }, $event)"
                    >
                        <div class="boxHeaderName">{{ modelBean.data.item.name }}</div>
                    </div>
                    <div class="boxBody">
                        <table cellspacing="0" width="100%" *ngIf="modelBean.data.item.title">
                            <tr>
                                <td>Title:</td>
                                <td>{{ modelBean.data.item.title }}</td>
                            </tr>
                        </table>
                        <table
                            cellspacing="0"
                            width="100%"
                            *ngFor="let row of modelBean.data.item.rows; trackBy: trackByFn"
                        >
                            <tr *ngIf="row.address">
                                <td>Address:</td>
                                <td>
                                    {{ row.address }}
                                    <span class="note">
                                        <span *ngIf="row.addr_type == 3">Work</span>
                                        <span *ngIf="row.addr_type == 4">Home</span>()
                                    </span>
                                </td>
                            </tr>
                            <tr *ngIf="row.phone">
                                <td>Phone:</td>
                                <td>
                                    {{ row.phone }}
                                    <span class="note"
                                        >(<span *ngIf="row.addr_type == 3">Work</span
                                        ><span *ngIf="row.addr_type == 4">Home</span>)</span
                                    >
                                </td>
                            </tr>
                            <tr *ngIf="row.fax">
                                <td>Fax:</td>
                                <td>
                                    {{ row.fax }}
                                    <span class="note"
                                        >(<span *ngIf="row.addr_type == 3">Work</span
                                        ><span *ngIf="row.addr_type == 4">Home</span>)</span
                                    >
                                </td>
                            </tr>
                            <tr *ngIf="row.e_mail">
                                <td>Email:</td>
                                <td>
                                    <a href="mailto:{{ row.e_mail }}">{{ row.e_mail }}</a>
                                    <span class="note"
                                        >(<span *ngIf="row.addr_type == 3">Work</span
                                        ><span *ngIf="row.addr_type == 4">Home</span>)</span
                                    >
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </ng-template>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ItemContactComponent extends S25ItemComponent {
    constructor(
        elementRef: ElementRef,
        zone: NgZone,
        cd: ChangeDetectorRef,
        componentFactoryResolver: ComponentFactoryResolver,
        renderer: Renderer2,
    ) {
        super(elementRef, zone, cd, componentFactoryResolver, renderer);
        this.itemTypeId = 3;
    }
}
