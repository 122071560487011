//@author: devin
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    NgZone,
    Renderer2,
    ViewEncapsulation,
} from "@angular/core";
import { S25ItemComponent } from "../s25.item.component";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-item-event")
@Component({
    selector: "s25-item-event",
    template: `
        <s25-item
            *ngIf="init"
            [modelBean]="modelBean"
            [includeTypeIcon]="includeTypeIcon"
            [popoverTemplate]="eventTooltip"
            [empty]="empty"
            [inactive]="inactive"
            [ariaLabel]="ariaLabel"
            ><ng-content></ng-content
        ></s25-item>

        <ng-template #eventTooltip>
            <div class="qtip-content">
                <div *ngIf="!modelBean.initPopOver">Loading...</div>
                <div *ngIf="modelBean.initPopOver && !modelBean.data?.item" class="eventBox popoverroot">
                    Could not load event details
                </div>
                <div *ngIf="modelBean.initPopOver && modelBean.data?.item" class="eventBox popoverroot">
                    <div
                        class="boxHeader"
                        (contextmenu)="contextMenu({ itemTypeId: 1, itemId: modelBean.itemId }, $event)"
                    >
                        <div class="boxHeaderName">{{ modelBean.data.item.event_name }}</div>
                    </div>
                    <div class="boxBody">
                        <div *ngIf="modelBean.data.item.rows">
                            <div *ngFor="let row of modelBean.data.item.rows.data; trackBy: trackByFn">
                                <div>
                                    <span *ngIf="row.type !== 2" class="c-dataLabel">{{ row.name }}</span>
                                    <span
                                        *ngIf="row.type == 2 && modelBean.data.item.rsrv_start_dt"
                                        class="c-dataLabel"
                                        >{{ row.name }}</span
                                    >
                                </div>
                                <div>
                                    <span *ngIf="row.type == 1">{{ row.value }}</span>
                                    <span *ngIf="row.type == 3" [innerHTML]="row.value"></span>
                                    <span *ngIf="row.type == 2 && modelBean.data.item.rsrv_start_dt">
                                        <span>
                                            {{
                                                modelBean.data.item.rsrv_start_dt
                                                    | dateFormat: modelBean.data.timeFormat
                                            }}
                                            <span
                                                *ngIf="
                                                    (modelBean.data.item.rsrv_start_dt
                                                        | dateFormat: modelBean.data.dateFormat) !==
                                                    (modelBean.data.item.rsrv_end_dt
                                                        | dateFormat: modelBean.data.dateFormat)
                                                "
                                                class="meridiem angular fulldate"
                                            >
                                                {{
                                                    " (" +
                                                        (modelBean.data.item.rsrv_start_dt
                                                            | dateFormat: modelBean.data.dateFormat) +
                                                        ")"
                                                }}
                                            </span>
                                            <span class="meridiem">{{ " - " }}</span>
                                        </span>
                                        <span>
                                            {{
                                                modelBean.data.item.rsrv_end_dt | dateFormat: modelBean.data.timeFormat
                                            }}
                                            <span
                                                *ngIf="
                                                    (modelBean.data.item.rsrv_start_dt
                                                        | dateFormat: modelBean.data.dateFormat) !==
                                                    (modelBean.data.item.rsrv_end_dt
                                                        | dateFormat: modelBean.data.dateFormat)
                                                "
                                                class="meridiem angular fulldate"
                                            >
                                                {{
                                                    " (" +
                                                        (modelBean.data.item.rsrv_end_dt
                                                            | dateFormat: modelBean.data.dateFormat) +
                                                        ")"
                                                }}
                                            </span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <ng-container
                                *ngFor="
                                    let loc of modelBean.data?.item?.spaces?.data;
                                    index as $index;
                                    trackBy: trackByFn
                                "
                            >
                                <div *ngIf="modelBean.data?.item?.spaces" class="dataItemSpaces">
                                    <span class="c-dataLabel">{{ ($index === 0 && "Location(s): ") || " " }}</span>
                                    <div class="ngItemSpace">
                                        <span
                                            *ngIf="loc.is_hidden != 1"
                                            class="ngInline s25-object s25-object-space summary"
                                            (click)="itemDetail({ itemTypeId: 4, itemId: loc.space_id }, $event)"
                                            (contextmenu)="contextMenu({ itemTypeId: 4, itemId: loc.space_id }, $event)"
                                        >
                                            <div class="icon-space ngInline s25-object s25-object-space summary"></div>
                                            <div class="ngInline s25-object s25-object-space summary">
                                                {{ loc.space_name }}
                                            </div>
                                        </span>
                                        <span *ngIf="loc.is_hidden == 1" class="ngInline">
                                            <div class="icon-space ngInline"></div>
                                            <div class="ngInline">{{ loc.space_name }}</div>
                                        </span>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ItemEventComponent extends S25ItemComponent {
    constructor(
        elementRef: ElementRef,
        zone: NgZone,
        cd: ChangeDetectorRef,
        componentFactoryResolver: ComponentFactoryResolver,
        renderer: Renderer2,
    ) {
        super(elementRef, zone, cd, componentFactoryResolver, renderer);
        this.itemTypeId = 1;
    }
}
