//@author: travis

import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { ImageCroppedEvent, ImageCropperComponent } from "ngx-image-cropper";
import { jSith } from "../../util/jquery-replacement";
import { TypeManagerDecorator } from "../../main/type.map.service";

export interface ImageUploadModelI {
    getImageData?: () => Promise<string>;
    getImageFileName?: () => string;
    getImageType?: () => string;
    getWidth?: () => number;
    getErrorMsg?: () => string;
}

export interface ImageActionDataI {
    imageName?: string;
    imageDesc?: string;
    imageFileName?: string;
    imageType?: string;
    imageData?: string; //base64 image or imageUri,
    imageId?: number;
    imageTypeId?: number;
}

@TypeManagerDecorator("s25-ng-image-upload")
@Component({
    selector: "s25-ng-image-upload",
    styles: [".overlay{--cropper-overlay-color: blue !important}"],
    template: `
        <ng-container *ngIf="!hasCropper">
            <div class="upload-btn-wrapper">
                <input
                    type="file"
                    #file
                    (change)="fileChangeEvent(file.files)"
                    class="ngUnboundedUpload c-margin-top--single c-margin-bottom--half"
                    value="'Choose a file'"
                />
                <button class="file-btn">Upload a file</button>
            </div>
            <div *ngIf="imageUri" class="s25-image-body">
                <span class="imagePreviewLabel">Image Preview</span>
                <!--<img [src]= "imageUri" [alt]="bean.title" [title]="imageObject.title" (error)="onError()" (load)="onLoad()">-->
                <img [src]="imageUri" alt="Image Upload Preview" />
            </div>
        </ng-container>

        <ng-container *ngIf="hasCropper">
            <ng-container *ngIf="options.hasRotate">
                <button (click)="rotate('right')">Rotate Right</button>
                <button (click)="rotate('left')">Rotate Left</button>
            </ng-container>

            <ng-container *ngIf="options.hasZoom">
                <button (click)="scale('-1')">Zoom Out</button>
                <button (click)="scale('1')">Zoom In</button>
            </ng-container>

            <div style="--cropper-overlay-color: gray">
                <image-cropper
                    #cropper
                    [imageBase64]="this.imageBase64String"
                    [maintainAspectRatio]="false"
                    [cropperMinHeight]="minHeight"
                    [cropperMaxHeight]="maxHeight"
                    [cropperMinWidth]="minWidth"
                    [cropperMaxWidth]="maxWidth"
                    [canvasRotation]="options.rotation"
                    (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded($event)"
                    (cropperReady)="cropperReady($event)"
                    (loadImageFailed)="loadImageFailed($event)"
                    [output]="'base64'"
                ></image-cropper>
            </div>
            <div class="upload-btn-wrapper">
                <input
                    type="file"
                    #file
                    (change)="fileChangeEvent(file.files)"
                    aria-label="'Upload Logo'"
                    class="ngCroppieUpload"
                    value="'Choose a file'"
                />
                <button class="file-btn">Upload a file</button>
            </div>
        </ng-container>

        <!--<img [src]="croppedImage"/>-->
        <div class="ngFinePrint c-margin-bottom--half">
            Note: a light gray background is used behind your image to display any transparent text or designs. It is
            not part of the image that will be uploaded.
        </div>
    `,
})
export class S25ImageUploadComponent implements OnInit {
    @Input() model: ImageUploadModelI = {}; //allows access to some image details
    @Input() imageUri: any = undefined; //base64 or url to image
    @Input() hasCropper: boolean = false;
    @Input() imgWidth: number = undefined; //not currently used
    @Input() imgHeight: number = undefined; //not currently used
    @Input() minWidth?: number;
    @Input() maxWidth?: number;
    @Input() minHeight?: number;
    @Input() maxHeight?: number;
    @Input() isModal: boolean;

    @Input() options: any = { rotation: 0, scale: 1 };

    @Output() uploadedImageChange = new EventEmitter();
    @ViewChild(ImageCropperComponent) cropperChild: ImageCropperComponent;

    uploadedFile: File;
    imageBase64String: any; //had trouble sending image to ngx-image-cropper in any other format
    init = true;
    croppedImage: string = "";
    croppedImageWidth: number;
    SUPPORTED_TYPES: string[] = ["png", "jpg", "jpeg", "gif"];
    errorMsg: string;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}
    ngOnInit(): void {
        //expose ability for creator to get image data
        this.createModel();
        this.setImageBase64(this.imageUri);
    }

    createModel() {
        this.model = this.model || {};
        this.model.getImageData = () => {
            if (this.imageUri) {
                if (this.hasCropper) {
                    return jSith.when(this.croppedImage);
                } else {
                    return jSith.when(this.imageUri);
                }
            } else {
                return jSith.when(); //empty promise bc no image has been uploaded and no imageUri was passed in initially
            }
        };

        this.model.getImageFileName = () => {
            if (this.uploadedFile) {
                return this.uploadedFile.name;
            }
        };

        this.model.getImageType = () => {
            let fileName = this.model.getImageFileName();
            return fileName.substring(fileName.lastIndexOf(".") + 1);
        };

        //expose ability for creator to get width
        this.model.getWidth = () => {
            if (this.hasCropper) {
                return this.croppedImageWidth;
            }
        };

        this.model.getErrorMsg = () => {
            return this.errorMsg;
        };
    }

    rotate(direction: string) {
        this.options.rotation = "right" === direction ? this.options.rotation-- : this.options.rotation++;
    }
    scale(times: string) {
        this.options.scale += times;
    }

    getBase64(file: File) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    setImageBase64(data: any) {
        //'png'| 'jpg'| 'jpeg'| 'gif'){
        this.imageBase64String = data;
        setTimeout(() => {
            //Set time out to ensure image is set BEFORE detecting changes - was an issue with configure theme
            this.uploadedImageChange.emit(this.imageBase64String);
            this.cd.detectChanges();
        }, 500);
    }

    fileChangeEvent(files: any): void {
        this.errorMsg = "";
        if (files && files[0]) {
            let file = files[0];

            let sizeMb = file.size / 1024 / 1024;
            let fileType = "UNSUPPORTED";
            let fileTypeLoc = ("" + file.name).lastIndexOf(".");
            if (fileTypeLoc > -1) {
                fileType = ("" + file.name).substring(fileTypeLoc + 1).toLowerCase();
            }

            if (this.SUPPORTED_TYPES.indexOf(fileType) < 0) {
                this.errorMsg =
                    "The file type '" + fileType + "' is not supported. Please upload a png, jpg, jpeg, or gif file.";
            } else if (sizeMb > 5.0) {
                this.errorMsg = "Please upload a file with a size of less than 5MB.";
            } else {
                this.uploadedFile = file;
                this.getBase64(file).then((data) => {
                    this.imageUri = data;
                    this.setImageBase64(data);
                });
            }
        } else {
            this.errorMsg =
                "Your browser does not support the FileReader API. Please use a modern browser for this functionality.";
        }
        if (this.errorMsg) {
            this.isModal ? this.uploadedImageChange.emit(this.imageBase64String) : alert(this.errorMsg);
        }
        this.cd.detectChanges();
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.croppedImageWidth = event.width;
        this.cd.detectChanges();
    }
    imageLoaded(event: any) {
        this.cd.detectChanges();
        // show cropper
    }
    cropperReady(event: any) {
        // cropper ready
        this.cd.detectChanges();
    }
    loadImageFailed(event: any) {
        this.cd.detectChanges();
        // show message
    }
}
