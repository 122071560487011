import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { SpaceService } from "../../services/space.service";
import { ObjectPermissionService } from "../../services/object.permission.service";

@TypeManagerDecorator("s25-ng-location-always-share")
@Component({
    selector: "s25-ng-location-always-share",
    template: `
        <div *ngIf="isInit">
            <div *ngIf="canEdit && editModeOn" class="alwaysShareWrapper">
                <label>Always Share</label>
                <s25-toggle-button
                    [onChange]="updateAlwaysShare"
                    [modelValue]="alwaysShare"
                    [falseLabel]="'Off'"
                    [trueLabel]="'On'"
                    [toggleLabel]="'Always Share'"
                ></s25-toggle-button>
            </div>
            <div *ngIf="!canEdit || !editModeOn">Always Share: {{ alwaysShare ? "Yes" : "No" }}</div>
        </div>
    `,
    styles: `
        ::ng-deep s25-ng-location-always-share s25-toggle-button > div {
            height: unset !important;
        }

        ::ng-deep s25-ng-location-always-share s25-toggle-button .toggle-wrapper {
            margin-top: 0 !important;
        }

        .alwaysShareWrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .alwaysShareWrapper > label {
            margin: 0;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25LocationAlwaysShareComponent implements OnInit {
    @Input() spaceId: number;
    @Input() editModeOn: boolean;

    canEdit: boolean;
    alwaysShare: boolean;
    isInit: boolean = false;

    constructor(private changeDetector: ChangeDetectorRef) {}

    ngOnInit() {
        S25Util.all({
            alwaysShare: SpaceService.getSpaceAlwaysShare(this.spaceId),
            canEdit: ObjectPermissionService.canEdit(this.spaceId, 4),
        }).then((resp) => {
            this.canEdit = resp.canEdit;
            this.alwaysShare = resp.alwaysShare;
            this.isInit = true;
            this.changeDetector.detectChanges();
        });
    }

    updateAlwaysShare = (data: boolean) => {
        this.alwaysShare = data;
        SpaceService.setSpaceAlwaysShare(this.spaceId, this.alwaysShare);
    };
}
